import { StyleSheet, View } from 'react-native';
import { Button, IconButton, Text, TextInput } from 'react-native-paper';

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { addMatchup, resetMatchups, handleMatchupChange } from '../redux/players';

function NewMatchScreen({ navigation }) {
    const matchups = useSelector(state => state.players.matchups);
    const [hole, setHole] = useState(null);

    const dispatch = useDispatch();

    const matches = matchups.map((match, index) => {
        return(
            <View key={index}
                style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginTop: 20
            }}>
                <TextInput
                    style={styles.input}
                    placeholder='Player 1'
                    value={match[0]}
                    onChangeText={ (text) => dispatch(handleMatchupChange({i: index, j: 0, value: text})) } />
                <Text style={{ alignSelf: 'center'}}> vs. </Text>
                <TextInput
                    style={styles.input}
                    placeholder='Player 2'
                    value={match[1]}
                    onChangeText={ (text) => dispatch(handleMatchupChange({i: index, j: 1, value: text})) } />
            </View>
        );
    });

    return (
        <View style={{ margin: 15 }}>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <Text style={styles.titleText}>New Match</Text>
                <TextInput onChangeText={ setHole }
                           value={ hole }
                           label='Starting Hole'
                           keyboardType='numeric'
                           style={{ width: '40%'}} />
            </View>
            { matches }
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 25}}>
                <IconButton
                    icon="account-multiple-plus"
                    size={30}
                    onPress={() => {
                        dispatch(addMatchup(['', '']));
                    }} />
                <Button
                    style={{ width: 150, height: 40, justifyContent: 'center' }}
                    mode='contained'
                    onPress={() => {
                        navigation.navigate('Match', { hole: hole })
                    }}> Start </Button>
            </View>
            <Button
                mode='outlined'
                onPress={() => {
                    dispatch(resetMatchups());
                }}>Reset</Button>
        </View>
    );
}

const styles = StyleSheet.create({
    input: {
        flex: 1,
        justifyContent: 'center',
        height: 40
    },
    titleText: {
        fontSize: 25,
        fontWeight: "bold"
    }
});

export default NewMatchScreen;
