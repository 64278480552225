export const BASE_URL = 'https://goats.eprouty.com/api';

export const DISCORD_OAUTH = {
    clientId: '989560360493256745',
    clientSecret: 'vm6CEFUJF-biDnblj2EcpQnO2vidTDex',
    scopes: ['email', 'identify'],
    serviceConfiguration: {
        authorizationEndpoint: 'https://discordapp.com/api/oauth2/authorize',
        tokenEndpoint: 'https://discordapp.com/api/oauth2/token',
        revocationEndpoint: 'https://discordapp.com/api/oauth2/token/revoke'
    }
}
