import { View } from 'react-native';
import { Button, Text } from 'react-native-paper'
import { useSelector } from 'react-redux';

import Auth from '../components/auth';

export default function HomeScreen({ navigation }) {
    const data = useSelector(state => state.data.data);
    const state = useSelector(state => state.data.loading)

    return (
        <View style={{ margin: 15 }}>
            <Text>Welcome to the app Eric!</Text>
            <Button
                title="New Match"
                onPress={() => navigation.navigate('New Match')}
            />
            <Auth />
        </View>
    );
}
