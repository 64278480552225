import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import { BASE_URL } from '../config';

// First, define the reducer and action creators via `createSlice`
const playersSlice = createSlice({
    name: 'players',
    initialState: {
        /**
         * An array of objects each representing a player... the full data being pulled from the goats mongodb table if they are in there
         * Otherwise it's a partial object mainly consisting of name.
         */
        matchups: [['', '']],
        results: {},
        overall: {}
    },
    reducers: {
        addMatchup: (state, action) =>{
            state.matchups.push(action.payload);
        },
        handleMatchupChange: (state, action) => {
            const {i, j, value} = action.payload;
            state.matchups[i][j] = value;
        },
        setResult: (state, action) => {
            const { hole, player, value } = action.payload;
            if (!(player in state.results)){
                state.results[player] = [];
            }
            state.results[player][hole] = value;

            // Update player total
            let total = 0
            state.results[player].map( h => {
                total += h
            });
            state.overall[player] = total;
        },
        resetResults: state => {
            state.results = {}
            state.overall = {}
        },
        resetMatchups: state => {
            state.matchups = [['', '']]
        }
    },
})

// Destructure and export the plain action creators
export const { addMatchup, setResult, resetResults, resetMatchups, handleMatchupChange } = playersSlice.actions
export default playersSlice.reducer;


// Define a thunk that dispatches those action creators
export const upload = () => async (dispatch, getState) => {
    const {results, matchups, overall} = getState().players

    // Reformat results into a more manageable upload
    matchups.map( ([player1, player2]) => {
        let r = {
            [player1]: results[player1],
            [player2]: results[player2],
            winner: overall[player1] > overall[player2] ? player1 : overall[player1] === overall[player2] ? 'tie': player2
        }
        axios.post(`${BASE_URL}/match`, r);
    });
}
