import * as React from 'react';
import * as WebBrowser from 'expo-web-browser';
import { makeRedirectUri, ResponseType, useAuthRequest } from 'expo-auth-session';
// import { ScrollView, Text, Button } from 'react-native';

import { DISCORD_OAUTH } from '../config';
import axios from 'axios';
import { Menu } from 'react-native-paper';

WebBrowser.maybeCompleteAuthSession();

// Endpoint
const discovery = {
    authorizationEndpoint: DISCORD_OAUTH.serviceConfiguration.authorizationEndpoint,
    tokenEndpoint: DISCORD_OAUTH.serviceConfiguration.tokenEndpoint,
    revocationEndpoint: DISCORD_OAUTH.serviceConfiguration.revocationEndpoint
};

export default function Auth() {
    const [request, response, promptAsync] = useAuthRequest(
        {
            ...DISCORD_OAUTH,
            responseType: ResponseType.Token,
            redirectUri: makeRedirectUri()
        }, discovery);

    console.log(makeRedirectUri({
        scheme: 'com.eprouty.netcmatches'
    }))
    const [userInfo, setUserInfo] = React.useState({})

    React.useEffect(() => {
        if (response?.type === 'success') {
            const { access_token } = response.params;
            console.log(response.params);
            axios.get('https://discordapp.com/api/users/@me', { headers: { Authorization: `Bearer ${access_token}` } }).then((response) => {
                setUserInfo(response.data);
            }).catch(reason => {
                console.log(reason);
            })
        }
    }, [response]);

    return (
        <Menu.Item
            disabled={!request}
            title="Login"
            onPress={() => {
                promptAsync();
            }} />
    );
}
