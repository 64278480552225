import React from 'react';
import { useSelector } from 'react-redux';
import { ScrollView, StyleSheet } from 'react-native';
import { DataTable } from 'react-native-paper';

const calculateOutcome = (score, remaining) => {
    if(score === remaining){
        // The other player is dormie, mark it as so... but otherwise no change to outcome
        return `${score} up`;
    } else if (score > remaining){
        // This player has won!
        return `${score}&${remaining}`
    } else if (score > 0){
        // Player is winning, but hasn't won
        return `${score} up`
    }

    // Default case, no result
    return '';
}

export default function ResultsScreen() {
    const results = useSelector(state => state.players.results);
    const matchups = useSelector(state => state.players.matchups);
    const overall = useSelector(state => state.players.overall);

    const styles = StyleSheet.create({
        name: {
            width: 100
        },
        cell: {
            width: 30
        },
        overall: {
            width: 75,
            justifyContent: 'center',
            borderLeftWidth: 2,
            borderLeftColor: '#D3D3D3',
            fontWeight: 'bold'
        }
    });

    const player_results = [];
    matchups.map((match, index) => {
        player_results.push(
            <DataTable.Row key={`${index}_0`} style={{ borderBottomWidth: 0 }}>
                {[...Array(19)].map((e, i) =>
                    i === 0 ?
                        <DataTable.Cell key={i} style={styles.name}>{match[0]}</DataTable.Cell> :
                        <DataTable.Cell key={i} style={styles.cell}>{results[match[0]]?.[i] === undefined ? '' : results[match[0]]?.[i] || '-' }</DataTable.Cell>
                )}
                <DataTable.Cell style={styles.overall}>
                    { calculateOutcome(overall[match[0]] - overall[match[1]], 18 - results[match[0]].filter( e => e !== null).length) }
                </DataTable.Cell>
            </DataTable.Row>
        );
        player_results.push(
            <DataTable.Row key={`${index}_1`} style={{ borderBottomWidth: 4 }}>
                {[...Array(19)].map((e, i) =>
                    i === 0 ?
                        <DataTable.Cell key={i} style={styles.name}>{match[1]}</DataTable.Cell> :
                        <DataTable.Cell key={i} style={styles.cell}>{results[match[1]]?.[i] === undefined ? '' : results[match[1]]?.[i] || '-' }</DataTable.Cell>
                )}
                <DataTable.Cell style={styles.overall}>
                    {
                        overall[match[1]] > overall[match[0]] ? `${overall[match[1]] - overall[match[0]]} up` :
                            overall[match[1]] === overall[match[0]] ? 'push' : ''
                    }
                </DataTable.Cell>
            </DataTable.Row>
        );
    });
    return (
        <ScrollView horizontal>
            <DataTable>
                <DataTable.Header>
                    {[...Array(19)].map((e, i) =>
                        i === 0 ?
                            <DataTable.Title key={i} style={styles.name}>Player</DataTable.Title> :
                            <DataTable.Title key={i} style={styles.cell}>{i}</DataTable.Title>
                    )}
                    <DataTable.Title style={styles.overall}>Result</DataTable.Title>
                </DataTable.Header>
                {player_results}
            </DataTable>
        </ScrollView>
    );
}
