import { configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {combineReducers} from "redux";
import { reducer as network } from 'react-native-offline';
import thunk from 'redux-thunk';

import dataReducer from './dataTest';
import playersReducer from './players';

const reducers = combineReducers({
    data: dataReducer,
    players: playersReducer,
    network
});

const persistConfig = {
    key: 'primary',
    storage: AsyncStorage,
}

const persistedReducer = persistReducer(persistConfig, reducers);

export default configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({serializableCheck: false}).concat(thunk),
});
