import * as React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { getHeaderTitle } from '@react-navigation/elements';

// Redux and offline storage imports
import { ReduxNetworkProvider } from 'react-native-offline';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';

import { PaperProvider, Appbar, Divider, DefaultTheme, Menu } from 'react-native-paper';
import * as WebBrowser from 'expo-web-browser';

import store from './redux/store';

import Auth from './components/auth';

import HomeScreen from './screens/HomeScreen';
import NewMatchScreen from './screens/NewMatchScreen';
import MatchScreen from './screens/MatchScreen';
import ResultsScreen from './screens/ResultsScreen';
import { Platform } from 'react-native';

const Stack = createNativeStackNavigator();
let persistor = persistStore(store);

const theme = {
    ...DefaultTheme,
    roundness: 2,
    colors: {
        ...DefaultTheme.colors,
        primary: '#3498db',
        accent: '#f1c40f',
    },
};

const NavigationAppBar = ({ navigation, route, options, back }) => {
    const [visible, setVisible] = React.useState(false);
    const title = getHeaderTitle(options, route.name);

    return (
        <Appbar.Header>
            {back ? <Appbar.BackAction onPress={navigation.goBack} /> : null}
            <Appbar.Content title={title} />
            {!back ? (
                <Menu
                    visible={visible}
                    onDismiss={() => setVisible(false)}
                    anchor={
                        <Appbar.Action icon="menu" color="white" onPress={() => setVisible(true)} />
                    }>
                    <Menu.Item onPress={() => { WebBrowser.openBrowserAsync('https://goats.eprouty.com/support'); }} title="Support" />
                    <Menu.Item onPress={() => { WebBrowser.openBrowserAsync('https://goats.eprouty.com/privacy'); }} title="Privacy Statement" />
                    <Divider />
                    { Platform.OS !== 'ios' ? <Auth /> : null }
                </Menu>
            ) : null}
        </Appbar.Header>
    )
}

function App() {
    return (
        <Provider store={store}>
            <ReduxNetworkProvider>
                <PersistGate loading={true} persistor={persistor}>
                    <PaperProvider theme={theme}>
                        <NavigationContainer>
                            <Stack.Navigator initialRouteName='New Match' screenOptions={{ header: (props) => <NavigationAppBar {...props} /> }}>
                                <Stack.Screen name="Home" component={HomeScreen} />
                                <Stack.Screen name="New Match" component={NewMatchScreen} />
                                <Stack.Screen name="Match" component={MatchScreen} />
                                <Stack.Screen name="Results" component={ResultsScreen} options={{ orientation: 'all' }} />
                            </Stack.Navigator>
                        </NavigationContainer>
                    </PaperProvider>
                </PersistGate>
            </ReduxNetworkProvider>
        </Provider>
    );
}

export default App;
