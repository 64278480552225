import { SafeAreaView, StyleSheet, View, Pressable } from 'react-native';
import { Button, Text } from 'react-native-paper';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setResult, resetResults, upload } from '../redux/players';

const styles = StyleSheet.create({
    button: {
      alignItems: 'center',
      justifyContent: 'center',
      paddingVertical: 12,
      margin: 0.5,
      borderRadius: 4,
      borderWidth: 1,
      width: '40%',
      backgroundColor: 'white'
    },
    text: {
      fontSize: 16,
      lineHeight: 21,
      fontWeight: 'bold',
      letterSpacing: 0.25,
      color: 'white',
    },
    titleText: {
        fontSize: 20,
        fontWeight: "bold"
    }
});

/**
 * ToDo List:
 * Remember the starting hole and loop around that instead of needing to go back to 1
 */
export default function MatchScreen({ route, navigation }) {
    const [holesPlayed, setHolesPlayed] = useState(0);
    const [leaderboard, setLeaderboard] = useState([]);
    let tmpHole = parseInt(route.params.hole);
    const holeOrder = [...Array(18)].map(() => {
        if (tmpHole <= 18){
            return tmpHole++;
        } else {
            tmpHole = 1;
            return tmpHole++;
        }
    });
    const matchups = useSelector(state => state.players.matchups);
    const results = useSelector(state => state.players.results);
    const overall = useSelector(state => state.players.overall);
    const dispatch = useDispatch();

    useEffect( () => {
        // Check to see if this hole has any results for it, if not mark it as a push since that's the default state
        matchups.map( (match) => {
            if (results?.[match[0]]?.[holeOrder[holesPlayed]] === undefined){
                dispatch(setResult({hole: holeOrder[holesPlayed], player: match[0], value: 0}));
                dispatch(setResult({hole: holeOrder[holesPlayed], player: match[1], value: 0}));
            }
        });
    }, [holesPlayed]);

    useEffect( () => {
        // Update the leaderboard
        const tmp = [...leaderboard]
        matchups.map( (match, index) => {
            tmp[index] = overall[match[0]] > overall[match[1]] ? `${match[0]}: ${overall[match[0]] - overall[match[1]]} up` :
                overall[match[0]] === overall[match[1]] ? `Tie` : `${match[1]}: ${overall[match[1]] - overall[match[0]]} up`
        });
        setLeaderboard(tmp);
    }, [overall]);

    const matches = matchups.map( (match, index) => {
        return(
            <View key={index}>
                <View style={{flexDirection: 'row', marginTop: 15, justifyContent: 'center'}}>
                    <Pressable onPress={() => {
                                    dispatch(setResult({hole: holeOrder[holesPlayed], player: match[0], value: 1}));
                                    dispatch(setResult({hole: holeOrder[holesPlayed], player: match[1], value: 0}));
                            }}
                            style={[styles.button, {backgroundColor:  results?.[match[0]]?.[holeOrder[holesPlayed]] ? 'green' : 'white'}]}>
                        <Text>{match[0]}</Text>
                    </Pressable>
                    <Pressable onPress={() => {
                                    dispatch(setResult({hole: holeOrder[holesPlayed], player: match[0], value: 0}));
                                    dispatch(setResult({hole: holeOrder[holesPlayed], player: match[1], value: 1}));
                            }}
                            style={[styles.button, {backgroundColor:  results?.[match[1]]?.[holeOrder[holesPlayed]] ? 'teal' : 'white'}]}>
                        <Text>{match[1]}</Text>
                    </Pressable>
                    <Pressable onPress={() => {
                                    dispatch(setResult({hole: holeOrder[holesPlayed], player: match[0], value: 0}));
                                    dispatch(setResult({hole: holeOrder[holesPlayed], player: match[1], value: 0}));
                            }}
                            style={[styles.button, {width: '20%', backgroundColor:  (results?.[match[0]]?.[holeOrder[holesPlayed]] || results?.[match[1]]?.[holeOrder[holesPlayed]]) ? 'white' : 'gray'}]}>
                        <Text>Push</Text>
                    </Pressable>
                </View>
                <Text style={{alignSelf: 'flex-end'}}>{leaderboard[index]}</Text>
            </View>
        );
    });

    return (
        <SafeAreaView style={{ margin: 15 }}>
            <View style={ {flexDirection: 'row', justifyContent: 'space-between'} }>
                <Text style={styles.titleText}>Hole {holeOrder[holesPlayed]}</Text>
                <Text style={styles.titleText}>{18-holesPlayed} Remaining</Text>
            </View>
            { matches }
            <View style={{flexDirection: 'row', justifyContent: 'space-around', marginTop: 15}}>
                <Button onPress={() => { setHolesPlayed(holesPlayed - 1) }} disabled={ holesPlayed == 0 }>Back</Button>
                { holesPlayed == 17 ?
                    <Button onPress={() => dispatch(upload()) } >Done</Button>:
                    <Button onPress={() => { setHolesPlayed(holesPlayed + 1) }} disabled={ holesPlayed == 17 }>Next</Button>
                }
                <Button onPress={() => { dispatch(resetResults()); setHolesPlayed(0) }} >Reset</Button>
            </View>
            <Button onPress={() => { navigation.navigate('Results')}}>Results</Button>
        </SafeAreaView>
    );
};
