import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import { BASE_URL } from '../config';

// First, define the reducer and action creators via `createSlice`
const dataSlice = createSlice({
    name: 'data',
    initialState: {
        loading: 'idle',
        data: ''
    },
    reducers: {
        dataLoading(state) {
            // Use a "state machine" approach for loading state instead of booleans
            if (state.loading === 'idle') {
                state.loading = 'pending'
            }
        },
        dataReceived(state, action) {
            if (state.loading === 'pending') {
                state.loading = 'idle'
                state.data = action.payload
            }
        },
    },
})

// Destructure and export the plain action creators
export const { dataLoading, dataReceived } = dataSlice.actions
export default dataSlice.reducer;

// Define a thunk that dispatches those action creators
export const fetchData = () => async (dispatch) => {
    dispatch(dataLoading())
    const response = await axios.get(`${BASE_URL}/data`);
    dispatch(dataReceived(response.data))
}
